import Service from "./service";
const customerService = {
    getSubResellerList: ({ResellerId, DealerId = '', searchText = ''}) => {
      return Service.get('getSubResellerListJSONByLimit', {
        ResellerId,
        DealerId,
        search: searchText,
        start: 0,
        limit: 500
      })
    }
};

export default customerService;